<template>
  <div></div>
</template>

<script>

export default {
  name: 'logout',

  created() {
    this.$store.dispatch('destroyToken')
      .then(() => {
          this.$router.push('/login');
      })
      .catch(() => {
        alert('Logout did not work.');
      })
  },
}
</script>